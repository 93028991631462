// export const backendUrl = () => 'http://www.auth24.local/api/v2/backend';
export const myURL = "presale.xcientos.com"; // www.app.local watchtoearn-xci.com
export const appTitle = "Xcientos";

export let URLSchema = "https"; // http or https

// eslint-disable-next-line
const socketType = () => {
  if (URLSchema === "https") {
    return "wss";
  } else {
    return "ws";
  }
}; // http or https
export const baseUrl = () => `${URLSchema}://${myURL}`;
export const appUrl = () => `${URLSchema}://${myURL}/api/v2/d`;
export const authUrl = () => `${URLSchema}://${myURL}/api/v2/auth`;
export const rangerUrl = () => `${socketType()}://${myURL}/api/v2/ranger`;
//not sure yet

//...

// export const captchaLogin = (): boolean => true;
// export const captcha = {
//         captchaType: 'recaptcha',
//         siteKey: ''
//     };

export const withCredentials = () => true;

export const minutesUntilAutoLogout = (): string => "20";
export const msAlertDisplayTime = (): string => "5000";
export const languages = ["en"];
export const passwordEntropyStep = (): number => 14;

export const balancesFetchInterval = (): string => "3000";

export const sessionCheckInterval = (): string => "15000";
export const defaultStorageLimit = () => 10;
export const isUsernameEnabled = (): boolean => true;
export const kycSteps = (): string[] => [
  "email",
  "phone",
  "profile",
  "document",
];


export const Statuses = {
  completed: "success",
  processing: "warning",
  failed: "danger",
  rejected: "danger",
  collected: "success",
  submitted: "warning",
  canceled: "danger",
  skipped: "warning",
  confirming: "warning",
  succeed: "success",
  pending: "warning",
};
